<template>
  <v-menu offset-y left nudge-bottom="14" min-width="230" content-class="user-profile-menu-content">
    <template v-slot:activator="{ on, attrs }">
      <v-badge bottom color="success" overlap offset-x="12" offset-y="12" class="ms-4" dot>
        <v-avatar size="40px" v-bind="attrs" v-on="on">
          <v-img :src="showPhotoProfile()"></v-img>
        </v-avatar>
      </v-badge>
    </template>
    <v-list>
      <div class="pb-3 pt-2">
        <v-badge bottom color="success" overlap offset-x="12" offset-y="12" class="ms-4" dot>
          <v-avatar size="40px">
            <v-img :src="showPhotoProfile()"></v-img>
          </v-avatar>
        </v-badge>
        <div class="d-inline-flex flex-column justify-center ms-3" style="vertical-align: middle">
          <span class="text--primary font-weight-semibold mb-n1"> {{ pegawai.nama_lengkap }} &nbsp; </span>
          <small class="text--disabled text-capitalize">{{ pegawai.email }}</small>
          <small class="text--disabled text-capitalize">{{ pegawai.telp }}</small>
        </div>
      </div>

      <v-divider></v-divider>

      <!-- Profile -->
      <!-- <v-list-item link>
        <v-list-item-icon class="me-2">
          <v-icon size="22">
            {{ icons.mdiAccountOutline }}
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Profile</v-list-item-title>
        </v-list-item-content>
      </v-list-item> -->

      <!-- Email -->
      <!-- <v-list-item link>
        <v-list-item-icon class="me-2">
          <v-icon size="22">
            {{ icons.mdiEmailOutline }}
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Inbox</v-list-item-title>
        </v-list-item-content>
      </v-list-item> -->

      <!-- Chat -->
      <!-- <v-list-item link>
        <v-list-item-icon class="me-2">
          <v-icon size="22">
            {{ icons.mdiChatOutline }}
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Chat</v-list-item-title>
        </v-list-item-content>

        <v-list-item-action>
          <v-badge inline color="error" content="2"> </v-badge>
        </v-list-item-action>
      </v-list-item> -->

      <!-- <v-divider class="my-2"></v-divider> -->

      <!-- Settings -->
      <!-- <v-list-item link>
        <v-list-item-icon class="me-2">
          <v-icon size="22">
            {{ icons.mdiCogOutline }}
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Settings</v-list-item-title>
        </v-list-item-content>
      </v-list-item> -->

      <!-- Pricing -->
      <!-- <v-list-item link>
        <v-list-item-icon class="me-2">
          <v-icon size="22">
            {{ icons.mdiCurrencyUsd }}
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Pricing</v-list-item-title>
        </v-list-item-content>
      </v-list-item> -->

      <!-- FAQ -->
      <!-- <v-list-item link>
        <v-list-item-icon class="me-2">
          <v-icon size="22">
            {{ icons.mdiHelpCircleOutline }}
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>FAQ</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-divider class="my-2"></v-divider> -->

      <!-- Logout -->
      <v-list-item link>
        <v-list-item-icon class="me-2">
          <v-icon size="22">
            {{ icons.mdiLogoutVariant }}
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title><a @click.prevent="getLogout()">Logout</a></v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import {
  mdiAccountOutline,
  mdiEmailOutline,
  mdiCheckboxMarkedOutline,
  mdiChatOutline,
  mdiCogOutline,
  mdiCurrencyUsd,
  mdiHelpCircleOutline,
  mdiLogoutVariant,
} from '@mdi/js'

export default {
  data() {
    return {
      pegawai: [],
      token: localStorage.getItem('token'),

      icons: {
        mdiAccountOutline,
        mdiEmailOutline,
        mdiCheckboxMarkedOutline,
        mdiChatOutline,
        mdiCogOutline,
        mdiCurrencyUsd,
        mdiHelpCircleOutline,
        mdiLogoutVariant,
      },
    }
  },
  created() {
    // this.$isLoading(true)

    setTimeout(() => {
      // this.$isLoading(false)
      this.getUserLogin()
      this.showPhotoProfile()
    }, 2000)
  },
  methods: {
    async getLogout() {
      let uri = process.env.VUE_APP_ROOT_API + '/api/logout'
      await this.axios
        .post(uri, {
          token: this.token,
        })
        .then(response => {
          // this.msg = response.data
          // if (this.msg == "success") {
          localStorage.removeItem('token')
          this.$swal.fire({
            title: 'Success',
            text: 'Logout successfully',
            icon: 'success',
            showCancelButton: false,
            showConfirmButton: false,
            timer: 1000,
          })

          this.$router.push('/')
        })
    },
    showPhotoProfile() {
      //state token
      localStorage.getItem('token')
      if (this.pegawai.photo.length != 0) {
        if (this.pegawai.photo.length > 100) {
          return this.pegawai.photo
        }
        let imageClients =
          process.env.VUE_APP_ROOT_API + `/images/pegawai/${this.pegawai.id_pegawai}/${this.pegawai.photo}`
        // console.log(imageClients)
        return imageClients
      }
      let imageDefault = process.env.VUE_APP_ROOT_API + `/images/pegawai/no-image.png`
      return imageDefault
    },

    getUserLogin() {
      //state token
      localStorage.getItem('token')

      let uriLoginPegawai = process.env.VUE_APP_ROOT_API + '/api/loginpegawai'
      this.axios.get(uriLoginPegawai).then(response => {
        this.pegawai = response.data.pegawai
      })
    },
  },
}
</script>

<style lang="scss">
.user-profile-menu-content {
  .v-list-item {
    min-height: 2.5rem !important;
  }
}
</style>
