import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/admin/login',
  },
  {
    path: '/admin/login',
    name: 'admin-login',
    component: () => import('@/views/admin/Login.vue'),
    meta: {
      layout: 'blank',
    },
  },
  {
    path: '/admin/dashboard',
    name: 'admin-dashboard',
    component: () => import('@/views/admin/Dashboard.vue'),
  },
  // {
  //   path: '/admin/roles',
  //   name: 'roles-index',
  //   component: () => import('@/views/admin/roles/RolesIndex.vue'),
  // },
  // {
  //   path: '/admin/roles/create',
  //   name: 'roles-create',
  //   component: () => import('@/views/admin/roles/RolesCreate.vue'),
  // },
  // {
  //   path: '/admin/roles/edit/:id_role',
  //   name: 'roles-edit',
  //   component: () => import('@/views/admin/roles/RolesEdit.vue'),
  // },
  {
    path: '/admin/users',
    name: 'users-index',
    component: () => import('@/views/admin/users/UsersIndex.vue'),
  },
  {
    path: '/admin/users/create',
    name: 'users-create',
    component: () => import('@/views/admin/users/UsersCreate.vue'),
  },
  {
    path: '/admin/users/edit/:id_user',
    name: 'users-edit',
    component: () => import('@/views/admin/users/UsersEdit.vue'),
  },
  {
    path: '/admin/clients',
    name: 'clients-index',
    component: () => import('@/views/admin/clients/ClientsIndex.vue'),
  },
  {
    path: '/admin/clients/create',
    name: 'clients-create',
    component: () => import('@/views/admin/clients/ClientsCreate.vue'),
  },
  {
    path: '/admin/clients/edit/:id_member',
    name: 'clients-edit',
    component: () => import('@/views/admin/clients/ClientsEdit.vue'),
  },
  {
    path: '/admin/berkas',
    name: 'berkas-index',
    component: () => import('@/views/admin/berkas/BerkasIndex.vue'),
  },
  {
    path: '/admin/berkas/create',
    name: 'berkas-create',
    component: () => import('@/views/admin/berkas/BerkasCreate.vue'),
  },
  {
    path: '/admin/berkas/show/:id_berkas',
    name: 'berkas-show',
    component: () => import('@/views/admin/berkas/BerkasShow.vue'),
  },
  {
    path: '/admin/berkas/edit/:id_berkas',
    name: 'berkas-edit',
    component: () => import('@/views/admin/berkas/BerkasEdit.vue'),
  },
  {
    path: '/admin/companys',
    name: 'companys-index',
    component: () => import('@/views/admin/companys/CompanysIndex.vue'),
  },
  {
    path: '/admin/companys/edit/:id_pemilik',
    name: 'companys-edit',
    component: () => import('@/views/admin/companys/CompanysEdit.vue'),
  },
  {
    path: '/admin/costrupiah',
    name: 'costrupiah-index',
    component: () => import('@/views/admin/costrupiah/CostrupiahIndex.vue'),
  },
  {
    path: '/admin/costrupiah/create',
    name: 'costrupiah-create',
    component: () => import('@/views/admin/costrupiah/CostrupiahCreate.vue'),
  },
  {
    path: '/admin/costrupiah/edit/:id_biaya',
    name: 'costrupiah-edit',
    component: () => import('@/views/admin/costrupiah/CostrupiahEdit.vue'),
  },
  {
    path: '/admin/costdollar',
    name: 'costdollar-index',
    component: () => import('@/views/admin/costdollar/CostDollarIndex.vue'),
  },
  {
    path: '/admin/costdollar/create',
    name: 'costdollar-create',
    component: () => import('@/views/admin/costdollar/CostDollarCreate.vue'),
  },
  {
    path: '/admin/costdollar/edit/:id_biaya_dollar',
    name: 'costdollar-edit',
    component: () => import('@/views/admin/costdollar/CostDollarEdit.vue'),
  },
  {
    path: '/admin/employees',
    name: 'employees-index',
    component: () => import('@/views/admin/employees/EmployeesIndex.vue'),
  },
  {
    path: '/admin/employees/create',
    name: 'employees-create',
    component: () => import('@/views/admin/employees/EmployeesCreate.vue'),
  },
  {
    path: '/admin/employees/edit/:id_pegawai',
    name: 'employees-edit',
    component: () => import('@/views/admin/employees/EmployeesEdit.vue'),
  },
  {
    path: '/admin/country',
    name: 'country-index',
    component: () => import('@/views/admin/country/CountryIndex.vue'),
  },
  {
    path: '/admin/country/create',
    name: 'country-create',
    component: () => import('@/views/admin/country/CountryCreate.vue'),
  },
  {
    path: '/admin/country/edit/:id_negara',
    name: 'country-edit',
    component: () => import('@/views/admin/country/CountryEdit.vue'),
  },
  {
    path: '/admin/visacategory',
    name: 'visacategory-index',
    component: () => import('@/views/admin/visacategory/VisaCategoryIndex.vue'),
  },
  {
    path: '/admin/visacategory/create',
    name: 'visacategory-create',
    component: () => import('@/views/admin/visacategory/VisaCategoryCreate.vue'),
  },
  {
    path: '/admin/visacategory/edit/:id_statusijintinggal',
    name: 'visacategory-edit',
    component: () => import('@/views/admin/visacategory/VisaCategoryEdit.vue'),
  },
  {
    path: '/admin/permitstay',
    name: 'permitstay-index',
    component: () => import('@/views/admin/permitstay/PermitStayIndex.vue'),
  },
  {
    path: '/admin/permitstay/create',
    name: 'permitstay-create',
    component: () => import('@/views/admin/permitstay/PermitStayCreate.vue'),
  },
  {
    path: '/admin/permitstay/edit/:id_jenispermohonan',
    name: 'permitstay-edit',
    component: () => import('@/views/admin/permitstay/PermitStayEdit.vue'),
  },
  {
    path: '/admin/visas',
    name: 'visas-index',
    component: () => import('@/views/admin/visas/VisasIndex.vue'),
  },
  {
    path: '/admin/visas/create',
    name: 'visas-create',
    component: () => import('@/views/admin/visas/VisasCreate.vue'),
  },
  {
    path: '/admin/visas/edit/:id_visa/:id_member/:id_transaksi',
    name: 'visas-edit',
    component: () => import('@/views/admin/visas/VisasEdit.vue'),
  },
  {
    path: '/admin/visas/:id_member/:id_transaksi/transaksi',
    name: 'visas-transaksi',
    component: () => import('@/views/admin/visas/VisasTransaksi.vue'),
  },
  {
    path: '/admin/visas/:id_visa/pembayaran',
    name: 'visas-pembayaran',
    component: () => import('@/views/admin/visas/VisasPembayaran.vue'),
  },
  {
    path: '/admin/visas/:id_visa/:id_transaksi/invoice',
    name: 'visas-invoice',
    component: () => import('@/views/admin/visas/VisasInvoice.vue'),
    meta: {
      layout: 'blank',
    },
  },
  {
    path: '/admin/visas/:id_visa/:id_transaksi/tagihan',
    name: 'visas-tagihan',
    component: () => import('@/views/admin/visas/VisasTagihan.vue'),
    meta: {
      layout: 'blank',
    },
  },
  {
    path: '/admin/menus',
    name: 'menus-index',
    component: () => import('@/views/admin/menus/MenuIndex.vue'),
  },
  {
    path: '/admin/menus/create',
    name: 'menus-create',
    component: () => import('@/views/admin/menus/MenuCreate.vue'),
  },
  {
    path: '/admin/menus/edit/:id_menu',
    name: 'menus-edit',
    component: () => import('@/views/admin/menus/MenuEdit.vue'),
  },
  {
    path: '/admin/servicecategory',
    name: 'servicecategory-index',
    component: () => import('@/views/admin/servicecategory/ServiceCategoryIndex.vue'),
  },
  {
    path: '/admin/servicecategory/create',
    name: 'servicecategory-create',
    component: () => import('@/views/admin/servicecategory/ServiceCategoryCreate.vue'),
  },
  {
    path: '/admin/servicecategory/edit/:id_kategoriservice',
    name: 'servicecategory-edit',
    component: () => import('@/views/admin/servicecategory/ServiceCategoryEdit.vue'),
  },
  {
    path: '/admin/service',
    name: 'service-index',
    component: () => import('@/views/admin/service/ServiceIndex.vue'),
  },
  {
    path: '/admin/service/create',
    name: 'service-create',
    component: () => import('@/views/admin/service/ServiceCreate.vue'),
  },
  {
    path: '/admin/service/edit/:id_service',
    name: 'service-edit',
    component: () => import('@/views/admin/service/ServiceEdit.vue'),
  },
  {
    path: '/admin/staticpage',
    name: 'staticpage-index',
    component: () => import('@/views/admin/staticpage/StaticPageIndex.vue'),
  },
  {
    path: '/admin/staticpage/create',
    name: 'staticpage-create',
    component: () => import('@/views/admin/staticpage/StaticPageCreate.vue'),
  },
  {
    path: '/admin/staticpage/edit/:id_halamanstatis',
    name: 'staticpage-edit',
    component: () => import('@/views/admin/staticpage/StaticPageEdit.vue'),
  },
  {
    path: '/admin/product',
    name: 'product-index',
    component: () => import('@/views/admin/product/ProductIndex.vue'),
  },
  {
    path: '/admin/product/create',
    name: 'product-create',
    component: () => import('@/views/admin/product/ProductCreate.vue'),
  },
  {
    path: '/admin/product/edit/:id_produk',
    name: 'product-edit',
    component: () => import('@/views/admin/product/ProductEdit.vue'),
  },
  {
    path: '/error-404',
    name: 'error-404',
    component: () => import('@/views/Error.vue'),
    meta: {
      layout: 'blank',
    },
  },
  {
    path: '*',
    redirect: 'error-404',
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

export default router
